import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { SermonsService } from 'src/app/api/sermons/sermons.service';
import { TeamService } from 'src/app/api/team/team.service';
import { AddSermonComponent } from '../../sermons/add-sermon/add-sermon.component';

@Component({
  selector: 'app-add-team-member',
  templateUrl: './add-team-member.component.html',
  styleUrls: ['./add-team-member.component.scss']
})
export class AddTeamMemberComponent implements OnInit {

  form: FormGroup;
  saving: boolean = false;
  uploadedImageUrl: string;
  teamMemberData: any;

  SearchCountryField: any =  SearchCountryField;
  CountryISO: any = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.SouthAfrica, CountryISO.Botswana];
  selectedCountry: any = CountryISO.SouthAfrica;
  
  constructor(
    public dialogRef: MatDialogRef<AddTeamMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private teamProvider: TeamService
  ) {
    this.form = this.formBuilder.group({
      names: [this.data ? this.data.names : null, Validators.required],
      role: [this.data ? this.data.role : null, Validators.required],
      email: [this.data ? this.data.email : null, Validators.nullValidator],
      phoneNumber: [this.data ? this.data.phoneNumber : null, Validators.nullValidator],
      whatsApp: [this.data ? this.data.whatsApp : null, Validators.nullValidator],
      facebook: [this.data ? this.data.facebook : null, Validators.nullValidator],
      twitter: [this.data ? this.data.twitter : null, Validators.nullValidator],
    });

    if(this.data) {
      setTimeout(()=> {
        console.log(this.uploadedImageUrl);
        this.uploadedImageUrl = this.data.imageUrl ? this.data.imageUrl : '';
      }, 0)
    }
    else {
      this.uploadedImageUrl = '';
    }
  }

  ngOnInit() {
  }

  onImageUploaded(url) {
    setTimeout(()=> {
      this.uploadedImageUrl = url;
    }, 0)
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  removeImage() {
    this.uploadedImageUrl = '';
  }

  save() {
    this.teamMemberData = this.form.value;
    this.saving = true;
    this.teamMemberData.imageUrl = this.uploadedImageUrl;

    console.log(this.teamMemberData);
    if(this.data) {
      this.teamMemberData.imageUrl = this.uploadedImageUrl;
      this.teamMemberData.id = this.data.id;
      this.teamProvider.update(this.teamMemberData)
      .subscribe((data: any) => {
        this.snackBar.open(`${data.message}`, 'Dismiss', { duration: 5000 });
        this.onCancel();
      },(err) => {
        this.snackBar.open(err.error.message, 'Dismiss', { duration: 5000 });
      })
    }

    else {
      this.teamMemberData.imageUrl = this.uploadedImageUrl;
      this.teamProvider.create(this.teamMemberData)
      .subscribe((data)=> {
        this.saving = false;
        this.onCancel();
        this.snackBar.open('Team member added successfully', 'CLOSE', { duration: 5000 });
      },(error) => {
        console.log(error);
        this.snackBar.open(error.error.message, 'CLOSE', { duration: 5000 });
        this.saving = false;
      })
    }
  }


}
