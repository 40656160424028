import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgTinyUrlService } from 'ng-tiny-url';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  prodUrl = 'https://cfc-pretoria-north.herokuapp.com/api';
  // devUrl = 'https://cfc-pretoria-north.herokuapp.com/api';
  devUrl = 'http://localhost:8085/api';

  constructor(@Inject(DOCUMENT) private document: Document, private tinyUrl: NgTinyUrlService) { }

  getApiUrl() {
    if(this.document.location.hostname === 'localhost' || this.document.location.hostname === '127.0.0.1') {
      return this.devUrl;
    }
    else {
      return this.prodUrl
    }
  }

  getHostname() {
    return this.document.location.hostname;
  }

  shorternUrl(rawUrl) {
    return this.tinyUrl.shorten(rawUrl)
  }
}
