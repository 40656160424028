import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './modules/app-routing.module';
import { AppComponent } from './app.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PageBannerComponent } from './components/page-banner/page-banner.component';
import { EventsComponent } from './pages/events/events.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { TeamsComponent } from './pages/teams/teams.component';
import { PrayerComponent } from './pages/prayer/prayer.component';
import { PublicComponent } from './pages/public/public.component';
import { PublicRoutingModule } from './modules/public-routing.module';
import { AdminLandingComponent } from './admin/admin-landing/admin-landing.component';
import { MaterialModule } from './modules/material.designs';
import { AdminRoutingModule } from './modules/admin-routing.module';
import { LoginComponent } from './admin/pages/auth/login/login.component';
import { PasswordResetComponent } from './admin/pages/auth/password-reset/password-reset.component';
import { RecoverPasswordComponent } from './admin/pages/auth/recover-password/recover-password.component';
import { RegisterComponent } from './admin/pages/auth/register/register.component';
import { VerifyEmailComponent } from './admin/pages/auth/verify-email/verify-email.component';
import { NotesComponent } from './admin/pages/notes/notes.component';
import { PrayerRequestsComponent } from './admin/pages/prayer-requests/prayer-requests.component';
import { SermonsComponent } from './admin/pages/sermons/sermons.component';
import { TestimoniesComponent } from './admin/pages/testimonies/testimonies.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { EventsAdminComponent } from './admin/pages/events/events.component';
import { AuthGuardService } from './api/auth/auth-guard/auth-guard.service';
import { AuthService } from './api/auth/authentication/auth.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { ConfirmDeleteDialogComponent } from './components/re-usable/confirm-delete-dialog/confirm-delete-dialog.component';
import { AddEventComponent } from './modals/events/add-event/add-event.component';
import { ConfirmDialogService } from './providers/modals/confirm-dialog.service';
import { EventsService } from './api/events/events.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FileUploaderComponent } from './components/re-usable/file-uploader/file-uploader.component';
import { PublicSermonsComponent } from './pages/public-sermons/public-sermons.component';
import { AddSermonComponent } from './modals/sermons/add-sermon/add-sermon.component';
import { SermonsService } from './api/sermons/sermons.service';
import { MomentModule } from 'ngx-moment';
import { SermonCardComponent } from './components/sermon-card/sermon-card.component';
import { SermonDetailsComponent } from './pages/sermon-details/sermon-details.component';
import { ManageTeamsComponent } from './admin/pages/manage-teams/manage-teams.component';
import { AddTeamMemberComponent } from './modals/teams/add-team-member/add-team-member.component';
import { TeamService } from './api/team/team.service';
import { TeamMemberCardComponent } from './components/team-member-card/team-member-card.component';
import { PublicPrayerService } from './api/prayer/public-prayer.service';
import { PubliccTestimoniesComponent } from './pages/publicc-testimonies/publicc-testimonies.component';
import { VisionModalComponent } from './modals/vision-modal/vision-modal.component';
import { SubmitTestimonyComponent } from './pages/submit-testimony/submit-testimony.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AddNotesComponent } from './modals/notes/add-notes/add-notes.component';
import { NotesService } from './api/notes/notes.service';
import { AttendanceLandingComponent } from './attendance/pages/landing/landing.component';
import { AttendanceRoutingModule } from './modules/attendance-routing.module';
import { CurrentServiceComponent } from './attendance/pages/current-service/current-service.component';
import { CheckinComponent } from './attendance/pages/checkin/checkin.component';
import { RegisterMembersComponent } from './attendance/pages/register-members/register-members.component';
import { MemberService } from './api/member/members/member.service';
import { SubMemberService } from './api/member/sub-members/sub-member.service';
import { AddSubmemberComponent } from './attendance/pages/add-submember/add-submember.component';
import { AddMemberComponent } from './modals/members/add-member/add-member.component';
import { CheckinService } from './api/member/checkin/checkin.service';
import { PublicNoteComponent } from './pages/public-note/public-note.component';
import { NgTinyUrlModule, NgTinyUrlService } from 'ng-tiny-url';
import { ConfigService } from './api/config/config.service';
import { ChechinRegistryComponent } from './admin/pages/chechin-registry/chechin-registry.component';
import { MemberDetailsComponent } from './modals/member-details/member-details.component';
import { EnquiryService } from './api/emails/enquiry.service';

@NgModule({
  declarations: [
    AppComponent,
    ComingSoonComponent,
    HomeComponent,
    FooterComponent,
    AboutUsComponent,
    NavbarComponent,
    PageBannerComponent,
    EventsComponent,
    ContactUsComponent,
    TeamsComponent,
    PrayerComponent,
    PublicComponent,
    AdminLandingComponent,

    // Admin
    EventsAdminComponent,
    SermonsComponent,
    PrayerRequestsComponent,
    TestimoniesComponent,
    NotesComponent,
    LoginComponent,
    RegisterComponent,
    PasswordResetComponent,
    RecoverPasswordComponent,
    VerifyEmailComponent,
    ConfirmDeleteDialogComponent,
    AddEventComponent,
    FileUploaderComponent,
    PublicSermonsComponent,
    AddSermonComponent,
    SermonCardComponent,
    SermonDetailsComponent,
    ManageTeamsComponent,
    AddTeamMemberComponent,
    TeamMemberCardComponent,
    PubliccTestimoniesComponent,
    VisionModalComponent,
    SubmitTestimonyComponent,
    AddNotesComponent,
    AttendanceLandingComponent,
    CurrentServiceComponent,
    CheckinComponent,
    RegisterMembersComponent,
    AddSubmemberComponent,
    AddMemberComponent,
    PublicNoteComponent,
    ChechinRegistryComponent,
    MemberDetailsComponent,
  ],
  imports: [
    BrowserModule,
    // Routing
    PublicRoutingModule,
    AdminRoutingModule,
    AppRoutingModule,
    AttendanceRoutingModule,

    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAXpLHhjbXXDqcZOeENwCLimBuANdxP9O4'
    }),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    NgxIntlTelInputModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MomentModule,
    AngularEditorModule,
    NgTinyUrlModule
  ],
  providers: [
    BrowserModule,
    // Firebase
    AngularFirestore,
    AngularFireDatabase,

    AuthService,
    AuthGuardService,
    // Interceptor
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ConfirmDialogService,

    // APIs
    EventsService,
    SermonsService,
    TeamService,
    PublicPrayerService,,
    NotesService,
    MemberService,
    SubMemberService,
    CheckinService,
    NgTinyUrlService,
    ConfigService,
    EnquiryService
  ],
  entryComponents: [
    ConfirmDeleteDialogComponent,
    AddEventComponent,
    AddSermonComponent,
    AddTeamMemberComponent,
    VisionModalComponent,
    AddNotesComponent,
    AddMemberComponent,
    MemberDetailsComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
