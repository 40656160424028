import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/api/events/events.service';
import { SermonsService } from 'src/app/api/sermons/sermons.service';
import { TestimoniesService } from 'src/app/api/testimonies/testimonies.service';
import { VisionModalComponent } from 'src/app/modals/vision-modal/vision-modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  loading: boolean;
  sermonData: any;
  eventsData: any;
  testimoniesData: any;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private sermonsProvider: SermonsService,
    private eventsProvider: EventsService,
    private testimoniesProvider: TestimoniesService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.fetchSermons();
    this.fetchEvents();
    this.fetchTestimonies();
  }

  openAddress(address) {
    window.open(address, "_blank");
  }

  public openAddTestimony() {
    this.router.navigate(['/submit-testimony']);
  }

  public openContactUs() {
    this.router.navigate(['/contact-us']);
  }

  getLocation(item) {
    let location = { location: null, mapUrl: null }
    if(item.location && item.mapUrl) {
      location.location = item.location;
      location.mapUrl = item.mapUrl;
    }
    else if(!item.location || !item.mapUrl) {
      location.location = 'Mogotsi Lifestyle Centre, 74 boundary road, Heatherdale, Akassia';
      location.mapUrl = 'https://bit.ly/3tzNP2g';
    }

    return location
  }

  fetchSermons() {
    this.loading = true;
    this.sermonsProvider.getAll().subscribe((data) => {
      this.sermonData = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.sermonData = [];
      this.snackBar.open('Error occured while loading sermons', 'Dismiss', { duration: 5000 });
    })
  }

  fetchEvents() {
    this.loading = true;
    this.eventsProvider.getAll().subscribe((data) => {
      this.eventsData = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.eventsData = [];
      this.snackBar.open('Error occured while loading events', 'Dismiss', { duration: 5000 });
    })
  }

  fetchTestimonies() {
    this.loading = true;
    this.testimoniesProvider.getAll().subscribe((data:any) => {
      let publicTestimonies = data.filter((item) => item.isPublic && item.isApproved);
      this.testimoniesData = publicTestimonies;
      this.loading = false;
    }, (error)=> {
      this.loading = false;
      this.snackBar.open(error.error.message, 'Dismiss', { duration: 5000 });
    })
  }

  openVisionModal(type) {
    this.dialog.open(VisionModalComponent,{
      width: '500px',
      data: {type: type}
    }).afterClosed().subscribe((data)=> {
    });
  }

}
