import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { TeamService } from 'src/app/api/team/team.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {
  loading: boolean;
  teamData: any;


  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private teamProvider: TeamService
  ) { }

  ngOnInit() {
    this.fetchTeamMember();
  }

  fetchTeamMember() {
    this.loading = true;
    this.teamProvider.getAll().subscribe((data) => {
      this.teamData = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.teamData = [];
      this.snackBar.open('Error occured while loading team member', 'Dismiss', { duration: 5000 });
    })
  }

  getPastors() {
    return this.teamData ? this.teamData.filter((item) => item.role === 'Pastor') : [];
  }

  getVolunteers() {
    return this.teamData ?  this.teamData.filter((item) => item.role === 'Volunteer') : [];
  }

}
