import { Component, Input, OnInit } from '@angular/core';
declare var UIkit;
@Component({
  selector: 'app-page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss']
})
export class PageBannerComponent implements OnInit {
  @Input() image: any;
  @Input() heading: any;

  constructor() { }

  ngOnInit() {
    UIkit.img('#page-banner', {
      dataSrc: this.image
    });
  }

}
