import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { MemberService } from 'src/app/api/member/members/member.service';

@Component({
  selector: 'app-register-members',
  templateUrl: './register-members.component.html',
  styleUrls: ['./register-members.component.scss']
})
export class RegisterMembersComponent implements OnInit {
  form: FormGroup;
  SearchCountryField: any =  SearchCountryField;
	// TooltipLabel: any = TooltipLabel;
  CountryISO: any = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.SouthAfrica, CountryISO.Botswana];
  selectedCountry: any = CountryISO.SouthAfrica;
  saving: boolean = false;
  registering: boolean;
  submitted: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private memberProvider: MemberService,
    public snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      gender: ['', Validators.required],
    })
  }

  ngOnInit() {
      // console.log('');
  }

  onRegister(action: string) {
    this.registering = true;
    this.submitted = true;
    console.log(this.form);
    if (this.form.invalid) {
      this.registering = false;
        return;
    }
    let memberData = {
      ...this.form.value,
      ...{ lookUpNumber: this.form.value.phoneNumber.nationalNumber.split(/\s/).join('') },
      ...{ memberType: 'MAIN' }
    }
    console.log(memberData);
    this.memberProvider.create(memberData)
    .subscribe((data: any)=> {
      this.registering = false;
      this.snackBar.open('Member added successfully', 'CLOSE', { duration: 5000 });
      localStorage.setItem("cfc_pta_main_member", JSON.stringify(data));
      if(action === 'only') {
        this.router.navigate(['/event-checkin/service']);
      }
      else {
        this.router.navigate(['/event-checkin/add-submember', data.id]);
      }

    },(error) => {
      this.snackBar.open(error.error.message, 'CLOSE', { duration: 5000 });
      this.registering = false;
    })
  }

  get registerForm() { return this.form.controls; }
}