import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from 'src/app/api/events/events.service';
import { ConfirmDialogService } from 'src/app/providers/modals/confirm-dialog.service';
import * as moment from 'moment';

@Component({
  selector: 'app-current-service',
  templateUrl: './current-service.component.html',
  styleUrls: ['./current-service.component.scss']
})
export class CurrentServiceComponent implements OnInit {
  loading: boolean;
  eventsData: any;
  todaysEventData: any = [];
  currentEventId: string;

  constructor(
    private confirmDialog: ConfirmDialogService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private eventsProvider: EventsService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.fetchEvents();
  }

  datesAreOnSameDay(first, second) {
    return first.getFullYear() === second.getFullYear() &&
            first.getMonth() === second.getMonth() &&
            first.getDate() === second.getDate();
  }

  dateIsBefore(date) {
    return moment(new Date()).isBefore(date, 'day');
  }

  getTodaysEvent(events) {
    if(events.length) {
      this.todaysEventData = events.filter((event) => this.datesAreOnSameDay(new Date(event.dateTime[0]), new Date()) || (this.dateIsBefore(new Date(event.dateTime[0])) && event.earlyCheckin));
    }
    else {
      this.todaysEventData = [];
    }
    // console.log(this.todaysEventData);
  }

  fetchEvents() {
    this.loading = true;
    this.eventsProvider.getAll().subscribe((data) => {
      this.eventsData = data;
      this.getTodaysEvent(this.eventsData);
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.eventsData = [];
      this.todaysEventData = [];
      this.snackBar.open('Error occured while loading events', 'Dismiss', { duration: 5000 });
    })
  }
}
