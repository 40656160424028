import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/api/auth/authentication/auth.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  accessToken: any | undefined;
  tokenData: any;
  validToken: boolean | undefined;

  constructor(
    private authProvider: AuthService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      this.accessToken = params['token'];
    });
  }

  ngOnInit() {
    this.verifyToken();
  }

  verifyToken() {
    this.authProvider.verifyToken(this.accessToken).subscribe((data: any) => {
      this.validToken = true;
      this.verifyEmail({...{email: data.email}, ...{token: this.accessToken}})
    },(err:any) => {
      console.log(err);
      this.validToken = false;
    });
  }

  verifyEmail(verifyData: any) {
    this.authProvider.verifyEmail(verifyData).subscribe((data: any) => {
      this.validToken = true;
      this.tokenData = data;
    },(err: any) => {
      console.log(err);
      this.validToken = false;
    });
  }

}
