import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NotesService } from 'src/app/api/notes/notes.service';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-public-note',
  templateUrl: './public-note.component.html',
  styleUrls: ['./public-note.component.scss']
})
export class PublicNoteComponent implements OnInit {
  selectedNoteData: any;
  selectedNoteId: any;
  form: FormGroup;
  loading: boolean;

  tempNotes: any;

  @ViewChild('notesContent', {static: false}) pdfTable: ElementRef;


  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '400px',
      minHeight: '400px',
      maxHeight: '400px',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Add notes here...',
      defaultParagraphSeparator: '',
      defaultFontName: 'Arial',
      defaultFontSize: '12',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    // toolbarHiddenButtons: [
    //   ['bold', 'italic'],
    //   ['fontSize']
    // ]
  };
  toggleNotes: boolean = false;

  constructor(
    private notesProvider: NotesService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) {
    this.route.params.subscribe(params => {
      this.selectedNoteId = params['id'];
      this.fetchNote(this.selectedNoteId);
    });
  }

  ngOnInit() {
  }

  fetchNote(id) {
    this.loading = true;
    this.notesProvider.getNote(id).subscribe((data: any) => {
      this.selectedNoteData = data;
      this.tempNotes = this.selectedNoteData.notes;
      this.loading = false;
      this.form = this.formBuilder.group({
        notes: [this.selectedNoteData ? this.selectedNoteData.notes : null, Validators.nullValidator],
      });
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  downloadPDF() {
    // setTimeout(() => {
    //   var data = document.getElementById('notesContent');
    //   html2canvas(data).then(canvas => {
    //     var imgWidth = 210;   
    //     var pageHeight = 295;    
    //     var imgHeight = canvas.height * imgWidth / canvas.width;
    //     var heightLeft = imgHeight;
  
    //     const contentDataURL = canvas.toDataURL('image/png')  
    //     let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
    //     // var position = 0;  
    //     var margin = 10;
    //     // pdf.addImage(contentDataURL, 'PNG', 2, position, imgWidth, imgHeight, undefined, 'FAST');
    //     pdf.addImage(contentDataURL, 'PNG', margin, margin, imgWidth - (margin * 2), imgHeight - (margin * 2));
    //     pdf.save(`${this.selectedNoteData.title}.pdf`);
    //   });
    // }, 1000)

    var source = document.getElementById('notesContent');
    var specialElementHandlers = {
        '#notesContenta': function (element, renderer) {
            return true;
        }
    };
    var doc = new jsPDF('p', 'mm', 'a4');
    doc.setFont("Arial");
    doc.setFontType("normal");
    doc.setFontSize(24);
    doc.setTextColor(100);
    doc.fromHTML(source, 10, 10, {
        'width': 170,
        'elementHandlers': specialElementHandlers
    });
    doc.save(`${this.selectedNoteData.title}.pdf`);
  }

}
