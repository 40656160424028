import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/api/auth/authentication/auth.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  public recoveryForm: FormGroup;
  loading: boolean = false;
  accessToken: any;
  validToken: boolean = false;
  tokenData: any;
  submitted: boolean = false;
  form: FormGroup;
  isPasswordVisible: boolean = false;
  isConfirmPasswordVisible: boolean = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private authProvider: AuthService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.recoveryForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmNewPassword: ['', Validators.required]
    },{
      validator: MustMatch('newPassword', 'confirmNewPassword')
    });

    this.form = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmNewPassword: ['', Validators.required]
    }, {
      validator: MustMatch('newPassword', 'confirmNewPassword')
    });

    this.route.params.subscribe(params => {
      this.accessToken = params['token'];
    });
  }

  ngOnInit() {
    this.verifyToken();
  }

  public togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  public toggleConfirmPasswordVisibility() {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }

  verifyToken() {
    this.authProvider.verifyToken(this.accessToken).subscribe((data) => {
      this.validToken = true;
      this.tokenData = data;
      console.log(data);
    },(err) => {
      console.log(err);
      this.validToken = false;
    });
  }

  onRecoverPassword() {
    this.submitted = true;
    if (this.recoveryForm.invalid && this.validToken) {
      this.loading = false;
        return;
    }

    let passwordData = {
      ...{ password: this.recoveryForm.value.newPassword },
      ...{ email: this.tokenData.email }
    };

    this.authProvider.resetPasswordWithEmail(passwordData, this.accessToken).subscribe((data:any) => {
      console.log(data);
      this.snackBar.open(data.message, 'Dismiss', { duration: 5000 });
      this.recoveryForm.reset();
      this.router.navigate(['/login']);
    }, (error) => {
      this.snackBar.open(error.error.message, 'Dismiss', { duration: 5000 });
      this.loading = false;
    });
  }

}
