import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { EnquiryService } from 'src/app/api/emails/enquiry.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  lat: number = -25.66995017875991;
  lng: number = 28.173213768781775;
  zoom: number = 16;
  form: FormGroup;

  SearchCountryField: any =  SearchCountryField;
	// TooltipLabel: any = TooltipLabel;
  CountryISO: any = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.SouthAfrica, CountryISO.Botswana];
  selectedCountry: any = CountryISO.SouthAfrica;
  isUserLoggedIn: any;
  testimoniesData: any;
  contactData: any;
  saving: boolean = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private enquiryProvider: EnquiryService,
    public snackBar: MatSnackBar,
  ) {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.nullValidator],
      email: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  sendEnquiry() {
    this.contactData = this.form.value;
    this.saving = true;
    console.log('contactData', this.contactData);
    this.enquiryProvider.sendEnquiry(this.contactData)
    .subscribe((data) => {
      this.saving = false;
      this.snackBar.open('Thanks, we have received your details.', 'Dismiss', { duration: 5000 });
      this.form.reset();
    },(error) => {
      this.saving = false;
      this.snackBar.open(error.error.message, 'Dismiss', { duration: 5000 });
    })
  }
}
