import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { MemberService } from 'src/app/api/member/members/member.service';
import { SubMemberService } from 'src/app/api/member/sub-members/sub-member.service';
import { AddMemberComponent } from 'src/app/modals/members/add-member/add-member.component';
declare var UIkit;

@Component({
  selector: 'app-add-submember',
  templateUrl: './add-submember.component.html',
  styleUrls: ['./add-submember.component.scss']
})
export class AddSubmemberComponent implements OnInit {
  mainMemberData: any;
  loading: boolean;
  savedMainMemberData: any;
  subMemberData: any[];

  constructor(
    private route: ActivatedRoute,
    private memberProvider: MemberService,
    private subMemberProvider: SubMemberService,
    public snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,) {
    this.savedMainMemberData = JSON.parse(localStorage.getItem('cfc_pta_main_member'));
  }

  ngOnInit() {
    if(this.savedMainMemberData) {
      this.fetchMainMember(this.savedMainMemberData.id);
    }
    else {
      this.mainMemberData = null;
    }
  }

  onGoToAddMainMember() {
    this.router.navigate(['/event-checkin/register-member']);
  }

  fetchMainMember(id) {
    this.loading = true;
    this.memberProvider.getMember(id).subscribe((data) => {
      this.mainMemberData = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.mainMemberData = null;
      this.snackBar.open('Error loading member', 'CLOSE', { duration: 5000 });
    })
  }

  public onAddNewMember() {
    this.dialog.open(AddMemberComponent,{
      width: '850px',
      data: { subMember: null,  mainMember: this.savedMainMemberData}
    }).afterClosed().subscribe((data)=> {
      this.fetchMainMember(this.savedMainMemberData.id)
    });
  }

  openEditMember(item) {
    this.dialog.open(AddMemberComponent, {
      width: '850px',
      data: { subMember: item,  mainMember: this.savedMainMemberData}
    }).afterClosed().subscribe((data)=> {
      this.fetchMainMember(this.savedMainMemberData.id)
    });
  }

  openDeleteMember(data) {
    UIkit.modal.confirm('Do you want to delete this member?').then(() => {
        this.subMemberProvider.delete(data.id).subscribe((data) => {
          this.snackBar.open('Member deleted successfully', 'Dismiss', { duration: 5000 });
          this.fetchMainMember(this.savedMainMemberData.id)
        }, (error)=> {
          this.snackBar.open('Unable to delete member, please try again', 'Dismiss', { duration: 5000 });
        })
    }, ()=> {
        console.log('Rejected.')
    });
  }
  
}
