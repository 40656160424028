import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() { }
    intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

        const accessToken = JSON.parse(localStorage.getItem('cfc_pta_user'));

        if (accessToken) {
            const cloned = req.clone({
                headers: req.headers.set("Authorization", accessToken.accessToken)
            });
            return next.handle(cloned);
        }
        else {
            return next.handle(req);
        }
    }
}