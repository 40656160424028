import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { TeamService } from 'src/app/api/team/team.service';
import { AddTeamMemberComponent } from 'src/app/modals/teams/add-team-member/add-team-member.component';
declare var UIkit;

@Component({
  selector: 'app-manage-teams',
  templateUrl: './manage-teams.component.html',
  styleUrls: ['./manage-teams.component.scss']
})
export class ManageTeamsComponent implements OnInit {
  loading: boolean;
  teamData: any;


  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private teamProvider: TeamService
  ) { }

  ngOnInit() {
    this.fetchTeamMember();
  }

  public onAddTeamMember() {
    this.dialog.open(AddTeamMemberComponent,{
      width: '850px',
      data: null
    }).afterClosed().subscribe((data)=> {
      this.fetchTeamMember();
    });
  }

  fetchTeamMember() {
    this.loading = true;
    this.teamProvider.getAll().subscribe((data) => {
      this.teamData = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.teamData = [];
      this.snackBar.open('Error occured while loading team member', 'Dismiss', { duration: 5000 });
    })
  }


  openEditTeamMember(item) {
    this.dialog.open(AddTeamMemberComponent, {
      width: '850px',
      data: item
    }).afterClosed().subscribe((data)=> {
      this.fetchTeamMember();
    });
  }

  openDeleteTeamMember(data) {
    UIkit.modal.confirm('Do you want to delete this team member?').then(() => {
        this.teamProvider.delete(data.id).subscribe((data) => {
          this.snackBar.open('Event deleted successfully', 'Dismiss', { duration: 5000 });
          this.fetchTeamMember();
        }, (error)=> {
          this.snackBar.open('Unable to delete team mmember, please try again', 'Dismiss', { duration: 5000 });
        })
    }, ()=> {
        console.log('Rejected.')
    });
  }

}
