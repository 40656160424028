import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { EventsService } from 'src/app/api/events/events.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  loading: boolean;
  eventsData: any;

  constructor(
    private snackBar: MatSnackBar,
    private eventsProvider: EventsService
  ) { }

  ngOnInit(): void {
    this.fetchEvents();
  }

  fetchEvents() {
    this.loading = true;
    this.eventsProvider.getAll().subscribe((data) => {
      this.eventsData = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.eventsData = [];
      this.snackBar.open('Error occured while loading events', 'Dismiss', { duration: 5000 });
    })
  }
}
