import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class EnquiryService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private configProvider: ConfigService,
  ) {
    this.baseUrl = this.configProvider.getApiUrl();
  }

  sendEnquiry(data) {
    return this.http.post(`${this.baseUrl}/mails/send-enquiry`, data);
  }
  prayerResponse(data) {
    return this.http.post(`${this.baseUrl}/mails/prayer-response`, data);
  }
}
