import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { MemberService } from 'src/app/api/member/members/member.service';
import { SubMemberService } from 'src/app/api/member/sub-members/sub-member.service';
import { TeamService } from 'src/app/api/team/team.service';
import { AddTeamMemberComponent } from '../../teams/add-team-member/add-team-member.component';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {

  form: FormGroup;
  saving: boolean = false;
  uploadedImageUrl: string;
  subMemberData: any;
  
  constructor(
    public dialogRef: MatDialogRef<AddMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private subMemberProvider: SubMemberService
  ) {
    this.form = this.formBuilder.group({
      firstName: [this.data.subMember ? this.data.subMember.firstName : null, Validators.required],
      lastName: [this.data.subMember ? this.data.subMember.lastName : null, Validators.required],
      relationship: [this.data.subMember ? this.data.subMember.relationship : null, Validators.required],
      gender: [this.data.subMember ? this.data.subMember.gender : null, Validators.required],
    });
  }

  ngOnInit() {
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  save() {
    this.subMemberData = {
      ...this.form.value,
      ...{memberType: 'SUB'},
      ...{mainMember: this.data.mainMember.id},
    }
    this.saving = true;

    console.log(this.subMemberData);
    if(this.data.subMember) {
      this.subMemberData.id = this.data.subMember.id;
      this.subMemberProvider.update(this.subMemberData)
      .subscribe((data: any) => {
        this.snackBar.open(`${data.message}`, 'Dismiss', { duration: 5000 });
        this.onCancel();
      },(err) => {
        this.snackBar.open(err.error.message, 'Dismiss', { duration: 5000 });
      })
    }

    else {
      this.subMemberProvider.create(this.subMemberData)
      .subscribe((data)=> {
        this.saving = false;
        this.onCancel();
        this.snackBar.open('Member added successfully', 'CLOSE', { duration: 5000 });
      },(error) => {
        console.log(error);
        this.snackBar.open(error.error.message, 'CLOSE', { duration: 5000 });
        this.saving = false;
      })
    }
  }

}
