import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/api/config/config.service';
import { NotesService } from 'src/app/api/notes/notes.service';
import { SermonsService } from 'src/app/api/sermons/sermons.service';
declare var UIkit;
@Component({
  selector: 'app-sermon-details',
  templateUrl: './sermon-details.component.html',
  styleUrls: ['./sermon-details.component.scss']
})
export class SermonDetailsComponent implements OnInit {
  selectedSermonData: any;
  selectedSermonId: any;
  loading: boolean = false;
  notesData: any;
  currentURL: string;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private sermonsProvider: SermonsService,
    private route: ActivatedRoute,
    private notesProvider: NotesService,
    private configProvider: ConfigService,
    private router: Router
  ) {
    this.route.params.subscribe(params => {
      this.selectedSermonId = params['id'];
      this.fetchSermon(this.selectedSermonId);
    });
  }

  ngOnInit() {
    this.configProvider.shorternUrl(window.location.href).subscribe((data) => {
      this.currentURL = data;
    })
  }

  fetchSermon(id) {
    this.loading = true;
    this.sermonsProvider.getSermon(id).subscribe((data) => {
      this.selectedSermonData = data;
      this.fetchNotes(this.selectedSermonData.id);
      this.loading = false;
    }, (error) => {
      console.log(error || 'error getting sermon');
      this.notesData = null;
      this.loading = false;
    });
  }

  fetchNotes(sermon: string) {
    this.notesData = null;
    this.loading = true;
    this.notesProvider.getNoteBySermon(sermon).subscribe((data) => {
      this.notesData = data;
      this.loading = false;
    }, (error) => {
      console.log(error || 'error getting notes');
      this.notesData = null;
      this.loading = false;
    });
    this.notesData;
  }

  public closeDropdown() {
    UIkit.dropdown('#share-dropdown').hide();
  }
}
