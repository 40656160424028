import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { AuthService } from 'src/app/api/auth/authentication/auth.service';
import { TestimoniesService } from 'src/app/api/testimonies/testimonies.service';

@Component({
  selector: 'app-submit-testimony',
  templateUrl: './submit-testimony.component.html',
  styleUrls: ['./submit-testimony.component.scss']
})
export class SubmitTestimonyComponent implements OnInit {

  form: FormGroup;
  saving: boolean = false;
  testimonyData: any;

  SearchCountryField: any =  SearchCountryField;
	// TooltipLabel: any = TooltipLabel;
  CountryISO: any = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.SouthAfrica, CountryISO.Botswana];
  selectedCountry: any = CountryISO.SouthAfrica;
  isUserLoggedIn: any;
  testimoniesData: any;

  constructor(
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private testimoniesProvider: TestimoniesService,
    private authProvider: AuthService,
    private router: Router,
  ) {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.nullValidator],
      phoneNumber: ['', Validators.nullValidator],
      email: ['', Validators.nullValidator],
      title: ['', Validators.nullValidator],
      testimony: ['', Validators.nullValidator],
      isPublic: [false, Validators.nullValidator]
    });
  }
  

  ngOnInit() {
    this.isUserLoggedIn = this.authProvider.getCurrentUser();
  }

  public goToLogin() {
    this.router.navigate(['/login']);
    localStorage.setItem("cfc_pta_redirect", JSON.stringify({returnUrl: '/testimonies'}));
  }

  sendTestimony() {
    this.testimonyData = this.form.value;
    this.saving = true;
    this.testimonyData.user = this.isUserLoggedIn ? this.isUserLoggedIn.id : '';
    this.testimonyData.isApproved = false;
    console.log(this.testimonyData);
    this.testimoniesProvider.create(this.testimonyData)
    .subscribe((data) => {
      this.saving = false;
      this.form.reset();
      this.snackBar.open('Testimony is submitted.', 'Dismiss', { duration: 5000 });
    },(error) => {
      this.saving = false;
      this.snackBar.open(error.error.message, 'Dismiss', { duration: 5000 });
    })
  }

}
