import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router, NavigationStart, NavigationEnd, NavigationError, Event } from '@angular/router';
import { AuthService } from 'src/app/api/auth/authentication/auth.service';
import { PublicPrayerService } from 'src/app/api/prayer/public-prayer.service';

@Component({
  selector: 'app-admin-landing',
  templateUrl: './admin-landing.component.html',
  styleUrls: ['./admin-landing.component.scss']
})
export class AdminLandingComponent implements OnInit {
  isUserLoggedIn: any;
  loading: boolean;
  prayerRequestData: any;

  
  
  constructor(
    private router: Router,
    private authProvider: AuthService,
    private prayerProvider: PublicPrayerService,
    private snackBar: MatSnackBar,
    ) {
      this.isUserLoggedIn = this.authProvider.getCurrentUser();
      
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          // Show loading indicator
          console.log('Loading');
        }
        
        if (event instanceof NavigationEnd) {
          window.scroll(0,0);
        }
        
        if (event instanceof NavigationError) {
          console.log(event.error);
        }
      });
    }

    ngOnInit() {
      this.fetchPrayers();
      // setInterval(()=> {
      //   this.fetchPrayers();
      // }, 1000);
    }
    
    fetchPrayers() {
      this.loading = true;
      this.prayerProvider.getAll().subscribe((data) => {
        this.prayerRequestData = data;
        this.loading = false;
      }, (error) => {
        this.loading = false;
        this.prayerRequestData = [];
        this.snackBar.open('Error occured while loading prayer', 'Dismiss', { duration: 5000 });
      });
    }

    getPendingPrayers() {
      return this.prayerRequestData ? this.prayerRequestData.filter((item) => item.status === 'PENDING') : []
    }
}
