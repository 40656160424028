import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class TestimoniesService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private configProvider: ConfigService,
  ) {
    this.baseUrl = this.configProvider.getApiUrl();
  }

  create(data) {
    return this.http.post(`${this.baseUrl}/testimonies/create`, data);
  }

  search(searchText) {
    return this.http.get(`${this.baseUrl}/testimonies?searchText=${searchText}`);
  }

  getAll() {
    return this.http.get(`${this.baseUrl}/testimonies`);
  }

  getTestimony(id) {
    return this.http.get(`${this.baseUrl}/testimonies/${id}`);
  }

  update(data) {
    return this.http.put(`${this.baseUrl}/testimonies/${data.id}`, data);
  }

  delete(id) {
    return this.http.delete(`${this.baseUrl}/testimonies/${id}`);
  }
}
