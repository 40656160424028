import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl: string;
  accessToken: any;

  constructor(
    private http: HttpClient,
    private configProvider: ConfigService,
    ) {
      this.baseUrl = this.configProvider.getApiUrl();
      this.accessToken = this.getCurrentUser() ? this.getCurrentUser().accessToken : null;
    }

    randomState(howLong) {
	
      howLong=parseInt(howLong);
      
      if (!howLong || howLong<=0) {
        howLong=18;
      }
      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_";
  
      for (var i = 0; i < howLong; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
  }

  loginUser(loginData) {
    return this.http.post(`${this.baseUrl}/users/login`, loginData)
  }

  getUser(id) {
    return this.http.get(`${this.baseUrl}/users/${id}`);
  }

  updateDetails(data) {
    return this.http.put(`${this.baseUrl}/users/${data.userId}`, data);
  }
  changeEmail(data) {
    return this.http.put(`${this.baseUrl}/users/email/${data.userId}`, data);
  }
  resetPasswordWithEmail(data, token) {
    return this.http.post(`${this.baseUrl}/users/recover-password?token=${token}`, data);
  }

  changePassword(data) {
    return this.http.post(`${this.baseUrl}/users/change-password`, data);
  }

  sendPasswordRecovery(data) {
    return this.http.post(`${this.baseUrl}/users/send-password-recovery`, data);
  }

  verifyToken(token) {
    return this.http.post(`${this.baseUrl}/users/verify-token`, {token: token});
  }

  verifyEmail(data) {
    return this.http.post(`${this.baseUrl}/users/verify-email?token=${data.token}`, data);
  }

  registerUser(data) {
    delete data.passwordConfirm;
    return this.http.post(`${this.baseUrl}/users/register`, data)
  }

  logout() {
    localStorage.removeItem('cfc_pta_user');
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('cfc_pta_user'));
  }
}
