import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { MemberService } from 'src/app/api/member/members/member.service';
import { SubMemberService } from 'src/app/api/member/sub-members/sub-member.service';

@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.scss']
})
export class MemberDetailsComponent implements OnInit {
  memberDetails: any;
  constructor(
    public dialogRef: MatDialogRef<MemberDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
    private subMemberProvider: SubMemberService,
    private mainMemberProvider: MemberService,
  ) { }

  ngOnInit() {
    console.log(this.data);
    if(this.data.memberType === 'SUB') {
      this.fetchSubMember()
    }
    else {
      this.fetchMainbMember();
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  fetchSubMember() {
    this.subMemberProvider.getMember(this.data.member).subscribe((data) => {
      this.memberDetails = data;
    },(error) => {
      console.log(error);
    })
  }

  fetchMainbMember() {
    this.mainMemberProvider.getMember(this.data.member).subscribe((data) => {
      this.memberDetails = data;
    },(error) => {
      console.log(error);
    })
  }

}
