import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/app/api/events/events.service';
import { CheckinService } from 'src/app/api/member/checkin/checkin.service';
import { MemberService } from 'src/app/api/member/members/member.service';

@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.scss']
})
export class CheckinComponent implements OnInit {
  memberData: any[] = [
    {
      name: 'Eric Ratshili',
      relationship: 'FATHER'
    },
    {
      name: 'Elelwani Ratshili',
      relationship: 'MOTHER'
    },
    {
      name: 'Munei Ratshili',
      relationship: 'KID'
    },
    {
      name: 'Ritshidze Ratshili',
      relationship: 'KID'
    }
  ];
  checkInSuccess: boolean;
  currentEventId: any;
  mainMemberData: any;
  loading: boolean;
  savedMainMemberData: any;
  enteredText: any;
  searching: boolean;
  selectedMembers = [];
  selectedEventId: any;
  checkingIn: boolean;
  eventData: any;

  constructor(
    private route: ActivatedRoute,
    private memberProvider: MemberService,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private checkinProvider: CheckinService,
    private eventsProvider: EventsService
  ) {
    this.route.params.subscribe(params => {
      this.selectedEventId = params['event-id'];
      this.fetchEvent(this.selectedEventId);
    });
    this.savedMainMemberData = JSON.parse(localStorage.getItem('cfc_pta_main_member'));
  }

  ngOnInit() {
    if(this.savedMainMemberData) {
      this.fetchMainMember(this.savedMainMemberData.id);
    }
    else {
      this.mainMemberData = null;
    }
  }

  fetchEvent(id) {
    this.loading = true;
    this.eventsProvider.getEvent(id).subscribe((data) => {
      this.eventData = data;
      this.loading = false;
    }, (error)=> {
      this.loading = false;
      this.snackBar.open('Error fetching event', 'CLOSE', { duration: 5000 });
    })
  }

  fetchMainMember(id) {
    this.loading = true;
    this.memberProvider.getMember(id).subscribe((data) => {
      this.mainMemberData = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.mainMemberData = null;
      this.snackBar.open('Error loading member', 'CLOSE', { duration: 5000 });
    })
  }

  onCustomerSearch(event) {
    this.enteredText = event;
  }

  searchMembers() {
    this.searching = true;
    if(this.enteredText) {
      if(this.enteredText.length > 9) {
        this.memberProvider.search(this.enteredText).subscribe((data: any) => {
          this.loading = false;
          this.searching = false;
          if(data.length > 0) {
            this.mainMemberData = data[0];
            localStorage.setItem("cfc_pta_main_member", JSON.stringify(data[0]));

          }
          else {
            this.mainMemberData = null;
            this.snackBar.open('No member with this number found', 'CLOSE', { duration: 5000 });
          }
        }, (error) => {
          this.loading = false;
          this.mainMemberData = null;
          console.log(error);
          this.snackBar.open('No member with this number found', 'CLOSE', { duration: 5000 });
        });
      }
      else {
        this.searching = false;
        this.snackBar.open('Phone number not valid', 'CLOSE', { duration: 5000 });
      }
    }
    else {
      this.searching = false;
      this.snackBar.open('Please enter phone number', 'CLOSE', { duration: 5000 });
    }
  }

  public changeSelection(member, check) {
    if (check) {
      this.selectedMembers.push(member);
    } else {
      var index = this.selectedMembers.indexOf(member);
      this.selectedMembers.splice(index, 1);
    }
  };

  checkIn() {
    this.checkingIn = true;
    let checkinData = this.selectedMembers.map(obj=> ({ ...obj, event: this.selectedEventId, member: obj.id }));
    checkinData.forEach(object => {
      delete object['id'];
    });
    
    console.log(checkinData);
    if(this.selectedMembers.length) {
      this.checkinProvider.addCheckin(checkinData).subscribe((data) => {
        this.checkInSuccess = true;
        this.checkingIn = false;
      }, (error) => {
        this.checkingIn = false;
        this.snackBar.open(error.message || 'Error occured while trying to check in', 'CLOSE', { duration: 5000 });
      })
    }
    else {
      this.checkingIn = false;
      this.snackBar.open('Please select member(s) attending this event', 'CLOSE', { duration: 5000 });
    }
  }
}
