import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { NotesService } from 'src/app/api/notes/notes.service';
import { SermonsService } from 'src/app/api/sermons/sermons.service';
import { TeamService } from 'src/app/api/team/team.service';
import { AddTeamMemberComponent } from '../../teams/add-team-member/add-team-member.component';

@Component({
  selector: 'app-add-notes',
  templateUrl: './add-notes.component.html',
  styleUrls: ['./add-notes.component.scss']
})
export class AddNotesComponent implements OnInit {

  form: FormGroup;
  saving: boolean = false;
  notesData: any;
  sermonData: any;

  SearchCountryField: any =  SearchCountryField;
  CountryISO: any = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.SouthAfrica, CountryISO.Botswana];
  selectedCountry: any = CountryISO.SouthAfrica;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '400px',
      minHeight: '400px',
      maxHeight: '400px',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Add notes here...',
      defaultParagraphSeparator: '',
      defaultFontName: 'Arial',
      defaultFontSize: '12',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    // toolbarHiddenButtons: [
    //   ['bold', 'italic'],
    //   ['fontSize']
    // ]
  };
  loading: boolean;
  
  constructor(
    public dialogRef: MatDialogRef<AddNotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private notesProvider: NotesService,
    private sermonsProvider: SermonsService
  ) {
    this.form = this.formBuilder.group({
      notes: [this.data ? this.data.notes : null, Validators.required],
      sermon: [this.data ? this.data.sermon : null, Validators.required],
      title: [this.data ? this.data.title : null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.fetchSermons();
  }

  fetchSermons() {
    this.loading = true;
    this.sermonsProvider.getAll().subscribe((data) => {
      this.sermonData = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.sermonData = [];
      this.snackBar.open('Error occured while loading sermons', 'Dismiss', { duration: 5000 });
    })
  }
  onCancel(): void {
    this.dialogRef.close();
  }

  save() {
    this.notesData = this.form.value;
    this.saving = true;

    console.log(this.notesData);
    if(this.data) {
      this.notesData.id = this.data.id;
      this.notesProvider.update(this.notesData)
      .subscribe((data: any) => {
        this.snackBar.open(`${data.message}`, 'Dismiss', { duration: 5000 });
        this.onCancel();
      },(err) => {
        this.snackBar.open(err.error.message, 'Dismiss', { duration: 5000 });
      })
    }

    else {
      this.notesProvider.create(this.notesData)
      .subscribe((data)=> {
        this.saving = false;
        this.onCancel();
        this.snackBar.open('Notes added successfully', 'CLOSE', { duration: 5000 });
      },(error) => {
        console.log(error);
        this.snackBar.open(error.error.message, 'CLOSE', { duration: 5000 });
        this.saving = false;
      })
    }
  }

}
