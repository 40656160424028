import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLandingComponent } from '../admin/admin-landing/admin-landing.component';
import { ChechinRegistryComponent } from '../admin/pages/chechin-registry/chechin-registry.component';
import { EventsAdminComponent } from '../admin/pages/events/events.component';
import { ManageTeamsComponent } from '../admin/pages/manage-teams/manage-teams.component';
import { NotesComponent } from '../admin/pages/notes/notes.component';
import { PrayerRequestsComponent } from '../admin/pages/prayer-requests/prayer-requests.component';
import { SermonsComponent } from '../admin/pages/sermons/sermons.component';
import { TestimoniesComponent } from '../admin/pages/testimonies/testimonies.component';
import { AuthGuardService } from '../api/auth/auth-guard/auth-guard.service';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminLandingComponent,
    canActivate: [AuthGuardService],
    children: [
      { 
        path: '',
        redirectTo: 'events',
        pathMatch: 'full',
      },
      {
        path: 'events',
        component: EventsAdminComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'sermons',
        component: SermonsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'prayer-requests',
        component: PrayerRequestsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'testimonies',
        component: TestimoniesComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'notes',
        component: NotesComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'manage-team',
        component: ManageTeamsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'checkin-registry',
        component: ChechinRegistryComponent,
        canActivate: [AuthGuardService],
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
