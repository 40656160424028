import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { EventsService } from 'src/app/api/events/events.service';
import { AddEventComponent } from 'src/app/modals/events/add-event/add-event.component';
import { ConfirmDialogService } from 'src/app/providers/modals/confirm-dialog.service';
declare var UIkit;

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsAdminComponent implements OnInit {
  loading: boolean;
  eventsData: any;

  constructor(
    private confirmDialog: ConfirmDialogService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private eventsProvider: EventsService
  ) { }

  ngOnInit(): void {
    this.fetchEvents();
  }

  fetchEvents() {
    this.loading = true;
    this.eventsProvider.getAll().subscribe((data) => {
      this.eventsData = data;
      console.log('events loaded', data);
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.eventsData = [];
      this.snackBar.open('Error occured while loading events', 'Dismiss', { duration: 5000 });
    })
  }

  public onAddNewEvent() {
    this.dialog.open(AddEventComponent,{
      width: '850px',
      data: null
    }).afterClosed().subscribe((data)=> {
      this.fetchEvents();
    });
  }

  openEditEvent(item) {
    this.dialog.open(AddEventComponent, {
      width: '850px',
      data: item
    }).afterClosed().subscribe((data)=> {
      this.fetchEvents();
    });
  }

  openDeleteEvent(data) {
    UIkit.modal.confirm('Do you want to delete this event?').then(() => {
        this.eventsProvider.delete(data.id).subscribe((data) => {
          this.snackBar.open('Event deleted successfully', 'Dismiss', { duration: 5000 });
          this.fetchEvents();
        }, (error)=> {
          this.snackBar.open('Unable to delete event, please try again', 'Dismiss', { duration: 5000 });
        })
    }, ()=> {
        console.log('Rejected.')
    });
  }
}
