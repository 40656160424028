import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private configProvider: ConfigService,
  ) {
    this.baseUrl = this.configProvider.getApiUrl();
  }

  create(data) {
    return this.http.post(`${this.baseUrl}/notes/create`, data);
  }

  search(searchText) {
    return this.http.get(`${this.baseUrl}/notes?title=${searchText}`);
  }

  getAll() {
    return this.http.get(`${this.baseUrl}/notes`);
  }

  getNote(id) {
    return this.http.get(`${this.baseUrl}/notes/${id}`);
  }

  getNoteBySermon(sermonId) {
    return this.http.get(`${this.baseUrl}/notes/sermon/${sermonId}`);
  }

  update(data) {
    return this.http.put(`${this.baseUrl}/notes/${data.id}`, data);
  }

  delete(id) {
    return this.http.delete(`${this.baseUrl}/notes/${id}`);
  }
}
