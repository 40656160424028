import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { SermonsService } from 'src/app/api/sermons/sermons.service';

@Component({
  selector: 'app-public-sermons',
  templateUrl: './public-sermons.component.html',
  styleUrls: ['./public-sermons.component.scss']
})
export class PublicSermonsComponent implements OnInit {
  loading: boolean;
  sermonData: any;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private sermonsProvider: SermonsService
  ) { }

  ngOnInit(): void {
    this.fetchSermons();
  }

  fetchSermons() {
    this.loading = true;
    this.sermonsProvider.getAll().subscribe((data) => {
      this.sermonData = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.sermonData = [];
      this.snackBar.open('Error occured while loading sermons', 'Dismiss', { duration: 5000 });
    })
  }
}
