import { Component, Input, OnInit } from '@angular/core';
import { NotesService } from 'src/app/api/notes/notes.service';

@Component({
  selector: 'app-sermon-card',
  templateUrl: './sermon-card.component.html',
  styleUrls: ['./sermon-card.component.scss']
})
export class SermonCardComponent implements OnInit {
  @Input() sermon: any;
  loading: boolean = false;
  notesData: any;
  constructor(
    private notesProvider: NotesService
  ) { }

  ngOnInit() {
    this.fetchNotes(this.sermon.id);
  }

  fetchNotes(sermon: string) {
    this.notesData = null;
    this.notesProvider.getNoteBySermon(sermon).subscribe((data) => {
      this.notesData = data;
    }, (error) => {
      console.log(error || 'error getting notes');
      this.notesData = null;
    });
    this.notesData;
  }

}
