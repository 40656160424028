import { Component, Inject, OnChanges, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError, Event } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { AuthService } from './api/auth/authentication/auth.service';
declare let UIkit;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnChanges {
  title = 'cfc-pretoria';
  isUserLoggedIn: any;
  validToken: boolean;
  tokenData: any;
  tokenCheck: any;

  constructor(
    private router: Router,
    private authProvider: AuthService,
    private snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    
    
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
          window.scroll(0,0);
      }

      if (event instanceof NavigationError) {
          console.log(event.error);
      }
  });
  }

  ngAfterViewChecked() { /* */ }

  ngOnInit() {
    window.scrollTo(0, 0);
    
      // this.tokenCheck = setInterval(()=> {
      //   this.isUserLoggedIn = this.authProvider.getCurrentUser();
      //   if(this.isUserLoggedIn) {
      //     this.verifyToken();
      //   }
      // }, 5000);
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 5);
  }

  public logout() {
    this.authProvider.logout();
    this.router.navigate(['/login']);
    this.snackBar.open(`You've been logged out successfully`, 'Dismiss', { duration: 5000 });
  }

  verifyToken() {
    this.authProvider.verifyToken(this.isUserLoggedIn.accessToken).subscribe((data) => {
      this.validToken = true;
      this.tokenData = data;
      console.log('tokenData', this.tokenData);
      console.log(data);
    },(err) => {
      console.log(err);
      this.validToken = false;
      this.logout();
      this.triggerModal();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  triggerModal() {
    UIkit.modal.alert('Token expired! You are logged out').then(()=> {
      console.log('Alert closed.');
      // this.logout();
    });
    clearInterval(this.tokenCheck);
  }
}
