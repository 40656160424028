// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAJO1fafgqAJ-_z1TglGGvutgZ-2K_yv0o",
    authDomain: "cfc-pretoria-north.firebaseapp.com",
    projectId: "cfc-pretoria-north",
    storageBucket: "cfc-pretoria-north.appspot.com",
    messagingSenderId: "915429406044",
    appId: "1:915429406044:web:5f99718969b98931cffd99",
    measurementId: "G-KL5BVM0GBP"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
