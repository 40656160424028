import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../admin/pages/auth/login/login.component';
import { PasswordResetComponent } from '../admin/pages/auth/password-reset/password-reset.component';
import { RecoverPasswordComponent } from '../admin/pages/auth/recover-password/recover-password.component';
import { RegisterComponent } from '../admin/pages/auth/register/register.component';
import { VerifyEmailComponent } from '../admin/pages/auth/verify-email/verify-email.component';
import { HomeComponent } from '../pages/home/home.component';


const routes: Routes = [
  // {path: '', component: HomeComponent},
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
  },
  {
    path: 'recover-password/:token',
    component: RecoverPasswordComponent,
  },
  {
    path: 'verify-email/:token',
    component: VerifyEmailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
