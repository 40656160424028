import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { AuthService } from 'src/app/api/auth/authentication/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  loading: boolean = false;
  isPasswordVisible: boolean = false;
  returnUrl: string;
  prevRoute: string;
  isUserLoggedIn: any;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private authProvider: AuthService
  ) {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required] //demo@2020
    });
    this.router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    .subscribe((events: RoutesRecognized[]) => {
      this.prevRoute = events[0].urlAfterRedirects;
      // console.log('previous url', this.prevRoute);
    });
  }

  ngOnInit() {
    let urlToReturnTo = JSON.parse(localStorage.getItem('cfc_pta_redirect'));
    this.returnUrl = urlToReturnTo ? urlToReturnTo.returnUrl : '/';
    this.isUserLoggedIn = this.authProvider.getCurrentUser();
    if(this.isUserLoggedIn) {
      this.router.navigate(['/']);
    }
  }
  
  loginUser() {
    let userData = this.form.value;
    this.doLogin(userData);
  }

  public togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  doLogin(data) {
    this.loading = true;
    let userData = data;
    this.authService.loginUser(userData)
      .subscribe(response => {
        if(response) { 
          console.log(response);
          localStorage.setItem("cfc_pta_user", JSON.stringify(response));
          this.loading = false;
          this.snackBar.open('Logged successfully', 'Dismiss', { duration: 5000 });
          // TODO redirect to home if is public user
          // this.router.navigate(['./admin/events']);
          this.router.navigate([this.returnUrl]);
          localStorage.removeItem('cfc_pta_redirect');
        }
      }, (error) => {
        console.log(error);
        this.snackBar.open(error.error.message, 'Dismiss', { duration: 5000 });
        this.loading = false;
    });
  }
}
