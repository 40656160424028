import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { TestimoniesService } from 'src/app/api/testimonies/testimonies.service';
declare var UIkit;
@Component({
  selector: 'app-testimonies',
  templateUrl: './testimonies.component.html',
  styleUrls: ['./testimonies.component.scss']
})
export class TestimoniesComponent implements OnInit {

  loading: boolean;
  testinomiedData: any;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private testimoniesProvider: TestimoniesService
  ) { }

  ngOnInit(): void {
    this.fetchTestimonies();
  }

  fetchTestimonies() {
    this.loading = true;
    this.testimoniesProvider.getAll().subscribe((data) => {
      this.testinomiedData = data;
      console.log('testimonies', this.testinomiedData);
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.testinomiedData = [];
      this.snackBar.open('Error occured while loading testimonies', 'Dismiss', { duration: 5000 });
    })
  }

  public appproveTestiony(item) {
    UIkit.modal.confirm(`You are about to approve ${item.firstName}'s testimony`)
    .then(() => {
      this.updateTestimony(item);
    }, ()=> {
        console.log('Rejected.');
    });
  }

  private updateTestimony(data) {
    let newData = data;
    newData.isApproved = true;
    this.testimoniesProvider.update(newData).subscribe((data) => {
      this.fetchTestimonies();
      this.snackBar.open('Testimony has been approved', 'Dismiss', { duration: 5000 });
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.fetchTestimonies();
      this.snackBar.open('Error occured while loading testimony', 'Dismiss', { duration: 5000 });
    })
  }

}
