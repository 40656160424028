import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { EventsService } from 'src/app/api/events/events.service';
declare var UIkit;

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss']
})
export class AddEventComponent implements OnInit {
  form: FormGroup;
  uploadedImageUrl: string;
  eventData: any;
  saving: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private eventsProvider: EventsService
  ) {
    this.form = this.formBuilder.group({
      title: [this.data ? this.data.title : null, Validators.required],
      description: [this.data ? this.data.description : null, Validators.required],
      dateTime: [this.data ? this.data.dateTime : null, Validators.required],
      organiser: [this.data ? this.data.organiser : null, Validators.required],
      location: [this.data ? this.data.location : null, Validators.nullValidator],
      mapUrl: [this.data ? this.data.mapUrl : null, Validators.nullValidator],
      earlyCheckin: [this.data ? this.data.earlyCheckin : false, Validators.nullValidator]
    });

    if(this.data) {
      setTimeout(()=> {
        console.log(this.uploadedImageUrl);
        this.uploadedImageUrl = this.data.imageUrl ? this.data.imageUrl : '';
      }, 0)
    }
    else {
      this.uploadedImageUrl = '';
    }
  }

  ngOnInit() {
  }

  onImageUploaded(url) {
    setTimeout(()=> {
      this.uploadedImageUrl = url;
    }, 0)
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  removeImage() {
    this.uploadedImageUrl = '';
  }

  save() {
    this.eventData = this.form.value;
    this.saving = true;
    if(this.data) {
      this.eventData.imageUrl = this.uploadedImageUrl;
      this.eventData.id = this.data.id;
      this.eventsProvider.update(this.eventData)
      .subscribe((data: any) => {
        this.snackBar.open(`${data.message}`, 'Dismiss', { duration: 5000 });
        this.onCancel();
      },(err) => {
        this.snackBar.open(err.error.message, 'Dismiss', { duration: 5000 });
      })
    }

    else {
      this.eventData.imageUrl = this.uploadedImageUrl;
      this.eventsProvider.create(this.eventData)
      .subscribe((data)=> {
        this.saving = false;
        this.onCancel();
        this.snackBar.open('Event added successfully', 'CLOSE', { duration: 5000 });
      },(error) => {
        console.log(error);
        this.snackBar.open(error.error.message, 'CLOSE', { duration: 5000 });
        this.saving = false;
      })
    }
  }

}
