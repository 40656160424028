import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { EventsService } from 'src/app/api/events/events.service';
import { MemberDetailsComponent } from 'src/app/modals/member-details/member-details.component';

@Component({
  selector: 'app-chechin-registry',
  templateUrl: './chechin-registry.component.html',
  styleUrls: ['./chechin-registry.component.scss']
})
export class ChechinRegistryComponent implements OnInit {
  loading: boolean;
  eventsData: any;
  constructor(
    private snackBar: MatSnackBar,
    private eventsProvider: EventsService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.fetchEvents();
  }

  fetchEvents() {
    this.loading = true;
    this.eventsProvider.getAll().subscribe((data) => {
      this.eventsData = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.eventsData = [];
      this.snackBar.open('Error occured while loading events', 'Dismiss', { duration: 5000 });
    })
  }

  public openMemberDetails(item) {
    this.dialog.open(MemberDetailsComponent, {
      width: '850px',
      data: item
    })
  }
}
