import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { SermonsService } from 'src/app/api/sermons/sermons.service';
import { AddSermonComponent } from 'src/app/modals/sermons/add-sermon/add-sermon.component';
import { ConfirmDialogService } from 'src/app/providers/modals/confirm-dialog.service';
declare var UIkit;
@Component({
  selector: 'app-sermons',
  templateUrl: './sermons.component.html',
  styleUrls: ['./sermons.component.scss']
})
export class SermonsComponent implements OnInit {

  loading: boolean;
  sermonData: any;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private sermonsProvider: SermonsService
  ) { }

  ngOnInit(): void {
    this.fetchSermons();
  }

  fetchSermons() {
    this.loading = true;
    this.sermonsProvider.getAll().subscribe((data) => {
      this.sermonData = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.sermonData = [];
      this.snackBar.open('Error occured while loading sermons', 'Dismiss', { duration: 5000 });
    })
  }

  public onAddNewSermon() {
    this.dialog.open(AddSermonComponent,{
      width: '850px',
      data: null
    }).afterClosed().subscribe((data)=> {
      this.fetchSermons();
    });
  }

  openEditSermon(item) {
    this.dialog.open(AddSermonComponent, {
      width: '850px',
      data: item
    }).afterClosed().subscribe((data)=> {
      this.fetchSermons();
    });
  }

  openDeleteSermon(data) {
    UIkit.modal.confirm('Do you want to delete this sermon?').then(() => {
        this.sermonsProvider.delete(data.id).subscribe((data) => {
          this.snackBar.open('Sermon deleted successfully', 'Dismiss', { duration: 5000 });
          this.fetchSermons();
        }, (error)=> {
          this.snackBar.open('Unable to delete sermon, please try again', 'Dismiss', { duration: 5000 });
        })
    }, ()=> {
        console.log('Rejected.')
    });
  }

}
