import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AddEventComponent } from '../events/add-event/add-event.component';

@Component({
  selector: 'app-vision-modal',
  templateUrl: './vision-modal.component.html',
  styleUrls: ['./vision-modal.component.scss']
})
export class VisionModalComponent implements OnInit {
  visionData = [
    {
      slug: 'knowGod',
      title: 'KNOW GOD',
      description: 'Our Weekend Services are designed for people at all stages in their spiritual journey but are primarily designed for those who have not attended church before. It is our commitment for our Sunday service to be full of powerful worship and practical teachings.'
    },
    {
      slug: 'findFreedom',
      title: 'FIND FREEDOM',
      description: 'Small Groups have a simple purpose: to bring people together. God created us to live in relationship with others and only then can we live the full life He intends for us. Sharing in community life is part of our design but meaningful relationship aren’t always easy to find. This is why Small Groups exist-to make these life-changing relationship relevant and accessible to people as they find freedom.'
    },
    {
      slug: 'discoverPurpose',
      title: 'DISCOVER PURPOSE',
      description: 'We certainly believe that God created each of us on purpose and that when we discover what that purpose is, we can begin to live life with more meaning And joy! The Growth Track is designed to position people to live out their calling as a follower of Jesus while at CFC Pretoria North.'
    },
    {
      slug: 'makeDiff',
      title: 'MAKE A DIFFERENCE',
      description: 'All of us were created by God to make a difference in the world around us. God has a placed for all us where our unique abilities and passions can impact the lives of others for the better. We believe life gains meaning when people find, develop, and fulfill their purpose. The Dream Team is a platform where those who have discovered their gifts and passions are making a difference by actively serving others while at CFC Pretoria North.'
    }
  ];

  selectedData: any;
  constructor(
    public dialogRef: MatDialogRef<AddEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.selectedData = this.visionData.find((item) => item.slug === this.data.type)
    console.log(this.selectedData);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
