import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { NotesService } from 'src/app/api/notes/notes.service';
import { AddEventComponent } from 'src/app/modals/events/add-event/add-event.component';
import { AddNotesComponent } from 'src/app/modals/notes/add-notes/add-notes.component';
declare var UIkit;
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  notesData: any;
  loading: boolean;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private notesProvider: NotesService
  ) { }

  ngOnInit(): void {
    this.fetchSermons();
  }

  fetchSermons() {
    this.loading = true;
    this.notesProvider.getAll().subscribe((data) => {
      this.notesData = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.notesData = [];
      this.snackBar.open('Error occured while loading notes', 'Dismiss', { duration: 5000 });
    })
  }

  public onAddNewNote() {
    this.dialog.open(AddNotesComponent,{
      width: '850px',
      data: null
    }).afterClosed().subscribe((data)=> {
      this.fetchSermons();
    });
  }

  openEditNote(item) {
    this.dialog.open(AddNotesComponent, {
      width: '850px',
      data: item
    }).afterClosed().subscribe((data)=> {
      this.fetchSermons();
    });
    
  }

  openDeleteNote(data) {
    UIkit.modal.confirm('Do you want to delete this note?').then(() => {
        this.notesProvider.delete(data.id).subscribe((data) => {
          this.snackBar.open('Note deleted successfully', 'Dismiss', { duration: 5000 });
          this.fetchSermons();
        }, (error)=> {
          this.snackBar.open('Unable to delete notes, please try again', 'Dismiss', { duration: 5000 });
        })
    }, ()=> {
        console.log('Rejected.')
    });
  }

}
