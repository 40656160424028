import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/api/auth/authentication/auth.service';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {
  isUserLoggedIn: any;
  constructor(
    private authProvider: AuthService,
  ) {
    this.isUserLoggedIn = this.authProvider.getCurrentUser();
  }

  ngOnInit() {
    // console.log('app: ', this.isUserLoggedIn)
  }

}
