import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { AuthService } from 'src/app/api/auth/authentication/auth.service';
import { PublicPrayerService } from 'src/app/api/prayer/public-prayer.service';
import { SermonsService } from 'src/app/api/sermons/sermons.service';

@Component({
  selector: 'app-prayer',
  templateUrl: './prayer.component.html',
  styleUrls: ['./prayer.component.scss']
})
export class PrayerComponent implements OnInit {
  form: FormGroup;
  saving: boolean = false;
  prayerData: any;

  SearchCountryField: any =  SearchCountryField;
	// TooltipLabel: any = TooltipLabel;
  CountryISO: any = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.SouthAfrica, CountryISO.Botswana];
  selectedCountry: any = CountryISO.SouthAfrica;
  isUserLoggedIn: any;

  constructor(
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private prayerProvider: PublicPrayerService,
    private authProvider: AuthService,
    private router: Router,
  ) {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.nullValidator],
      phoneNumber: ['', Validators.nullValidator],
      email: ['', Validators.nullValidator],
      prayer: ['', Validators.nullValidator],
    });
  }
  

  ngOnInit() {
    this.isUserLoggedIn = this.authProvider.getCurrentUser();
  }

  public goToLogin() {
    this.router.navigate(['/login']);
    localStorage.setItem("cfc_pta_redirect", JSON.stringify({returnUrl: '/prayer'}));
  }

  sendPrayer() {
    this.prayerData = this.form.value;
    this.saving = true;
    this.prayerData.user = this.isUserLoggedIn ? this.isUserLoggedIn.id : '';
    this.prayerData.status = 'PENDING';
    console.log(this.prayerData);
    this.prayerProvider.create(this.prayerData)
    .subscribe((data: any) => {
      this.saving = false;
      this.form.reset();
      this.snackBar.open('Prayer request is submitted.', 'Dismiss', { duration: 5000 });
    },
    (err) => {
      this.saving = false;
      this.snackBar.open(err.error.message, 'Dismiss', { duration: 5000 });
    })
  }

}
