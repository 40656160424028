import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { SermonsService } from 'src/app/api/sermons/sermons.service';
declare var UIkit;

@Component({
  selector: 'app-add-sermon',
  templateUrl: './add-sermon.component.html',
  styleUrls: ['./add-sermon.component.scss']
})
export class AddSermonComponent implements OnInit {
  form: FormGroup;
  uploadedImageUrl: string;
  sermonData: any;
  saving: boolean = false;
  
  constructor(
    public dialogRef: MatDialogRef<AddSermonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private sermonsProvider: SermonsService
  ) {
    this.form = this.formBuilder.group({
      title: [this.data ? this.data.title : null, Validators.required],
      description: [this.data ? this.data.description : null, Validators.required],
      date: [this.data ? this.data.date : null, Validators.required],
      sourceType: [this.data ? this.data.sourceType : null, Validators.nullValidator],
      sourceUrl: [this.data ? this.data.sourceUrl : null, Validators.nullValidator],
      audioUrl: [this.data ? this.data.audioUrl : null, Validators.nullValidator],
      videoUrl: [this.data ? this.data.videoUrl : null, Validators.nullValidator],
      speaker: [this.data ? this.data.speaker : null, Validators.required],
    });

    if(this.data) {
      setTimeout(()=> {
        console.log(this.uploadedImageUrl);
        this.uploadedImageUrl = this.data.imageUrl ? this.data.imageUrl : '';
      }, 0)
    }
    else {
      this.uploadedImageUrl = '';
    }
  }

  ngOnInit() {
  }

  onImageUploaded(url) {
    setTimeout(()=> {
      this.uploadedImageUrl = url;
    }, 0)
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  removeImage() {
    this.uploadedImageUrl = '';
  }

  save() {
    this.sermonData = this.form.value;
    this.saving = true;
    this.sermonData.imageUrl = this.uploadedImageUrl;

    console.log(this.sermonData);
    if(this.data) {
      this.sermonData.imageUrl = this.uploadedImageUrl;
      this.sermonData.id = this.data.id;
      this.sermonsProvider.update(this.sermonData)
      .subscribe((data: any) => {
        this.snackBar.open(`${data.message}`, 'Dismiss', { duration: 5000 });
        this.onCancel();
      },(err) => {
        this.snackBar.open(err.error.message, 'Dismiss', { duration: 5000 });
      })
    }

    else {
      this.sermonData.imageUrl = this.uploadedImageUrl;
      this.sermonsProvider.create(this.sermonData)
      .subscribe((data)=> {
        this.saving = false;
        this.onCancel();
        this.snackBar.open('Sermon added successfully', 'CLOSE', { duration: 5000 });
      },(error) => {
        console.log(error);
        this.snackBar.open(error.error.message, 'CLOSE', { duration: 5000 });
        this.saving = false;
      })
    }
  }


}
