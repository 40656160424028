import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class CheckinService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private configProvider: ConfigService,
  ) {
    this.baseUrl = this.configProvider.getApiUrl();
  }

  addCheckin(data) {
    return this.http.post(`${this.baseUrl}/checkins/add-checkin`, data);
  }

  getAll() {
    return this.http.get(`${this.baseUrl}/checkins`);
  }
}
