import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/api/auth/authentication/auth.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  form: FormGroup;
  loading: boolean = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private authProvider: AuthService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.form = this.formBuilder.group({
      email: ['', Validators.required]
    })
  }

  ngOnInit() {
  }

  recoverPassword() {
    if (this.form.invalid) {
      this.loading = false;
        this.snackBar.open('Email address is required', 'Dismiss', { duration: 5000 });
        return;
    }
    let formData = {
      ...this.form.value
    };
    
    this.authProvider.sendPasswordRecovery(formData).subscribe((data:any) => {
      console.log(data);
      this.form.reset();
      // this.router.navigate(['/login']);
      this.snackBar.open(data.message, 'Dismiss', { duration: 5000 });
    }, (error) => {
      this.snackBar.open(error.error.message, 'Dismiss', { duration: 5000 });
    })
  }

}
