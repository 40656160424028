import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/api/auth/authentication/auth.service';
declare var UIkit;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isAuthorized: any;

  constructor(
    private router: Router,
    private authProvider: AuthService,
    private snackBar: MatSnackBar
  ) {
    this.isAuthorized = this.authProvider.getCurrentUser();
  }
  
  ngOnInit() {
  }

  onOpenMenu() {
    UIkit.modal('#menu').show();
  }

  onCloseMenu() {
    setTimeout(() => {
      UIkit.modal('#menu').hide();
    }, 100);
  }

  goToPage(page) {
    this.router.navigate([`/${page}`]);
    this.onCloseMenu();
  }

  logout() {
    this.authProvider.logout();
    this.snackBar.open(`You've been logged out successfully`, 'Dismiss', { duration: 5000 });
    this.onCloseMenu();
    this.isAuthorized = this.authProvider.getCurrentUser();
    this.router.navigate([this.router.url]);
    window.location.reload();
  }

}
