import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { AuthService } from 'src/app/api/auth/authentication/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  form: FormGroup;
  loading: boolean = false;
  authData: any;
  
	SearchCountryField: any =  SearchCountryField;
	// TooltipLabel: any = TooltipLabel;
  CountryISO: any = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.SouthAfrica, CountryISO.Botswana];
  selectedCountry: any = CountryISO.SouthAfrica;
  submittedWithError: boolean = false;
  state$: Observable<object> | undefined;
  industriesData: any;
  submitted: boolean = false;
  isSocialSignUp = false;
  stateData!: Observable<any>;
  showTermsError: boolean = false;
  isPasswordVisible: boolean = false;
  isConfirmPasswordVisible: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirm: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'passwordConfirm')
    })
  }

  ngOnInit() {
  }

  public togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  public toggleConfirmPasswordVisibility() {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }

  onRegister() {
    this.submitted = true;
    this.loading = true;

    if (this.form.invalid) {
      this.loading = false;
        return;
    }

    let userData = this.form.value;

    console.log('userData', userData);
    delete userData.passwordConfirm;
    userData.userType = 'PUBLIC';
    this.authService.registerUser(userData)
    .subscribe(authData => {
      this.loading = false;
      this.router.navigate(['./login']);
      this.snackBar.open(`We have sent an email with a confirmation link to ${authData['email']}  address.`, 'Dismiss', { duration: 8000 });
      this.form.reset();
    }, (error) => {
      console.log(error);
      this.submittedWithError = true;
      this.snackBar.open(error.error.message, 'Dismiss', { duration: 5000 });
      this.loading = false;
    });
  }

  get registerForm() { return this.form.controls; }

}
