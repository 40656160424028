import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { EnquiryService } from 'src/app/api/emails/enquiry.service';
import { EventsService } from 'src/app/api/events/events.service';
import { PublicPrayerService } from 'src/app/api/prayer/public-prayer.service';
import { ConfirmDialogService } from 'src/app/providers/modals/confirm-dialog.service';
declare var UIkit;
@Component({
  selector: 'app-prayer-requests',
  templateUrl: './prayer-requests.component.html',
  styleUrls: ['./prayer-requests.component.scss']
})
export class PrayerRequestsComponent implements OnInit {

  loading: boolean;
  prayerRequestData: any;

  constructor(
    private confirmDialog: ConfirmDialogService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private prayerProvider: PublicPrayerService,
    private enquiryProvider: EnquiryService,
  ) { }

  ngOnInit(): void {
    this.fetchPrayers();
  }

  fetchPrayers() {
    this.loading = true;
    this.prayerProvider.getAll().subscribe((data) => {
      console.log('DATA', data);
      this.prayerRequestData = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.prayerRequestData = [];
      this.snackBar.open('Error occured while loading prayer', 'Dismiss', { duration: 5000 });
    })
  }

  getPendingPrayers() {
    return this.prayerRequestData.filter((item) => item.status === 'PENDING')
  }

  public startPraying(item) {
    UIkit.modal.confirm(`You are about to pray for ${item.firstName}`)
    .then(() => {
      this.updatePrayer(item);
    }, ()=> {
        console.log('Rejected.');
    });
  }

  prayerResponse(data: any) {
    this.loading = true;
    this.enquiryProvider.prayerResponse(data).subscribe((data) => {
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.snackBar.open(error.error.message, 'Dismiss', { duration: 5000 });
    })
  }

  private updatePrayer(data) {
    let newData = data;
    newData.status = 'PRAYED';
    this.prayerProvider.update(newData).subscribe((data) => {
      this.fetchPrayers();
      this.prayerResponse(newData);
      this.snackBar.open('Prayer status is updated', 'Dismiss', { duration: 5000 });
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.fetchPrayers();
      this.snackBar.open('Error occured while loading prayer', 'Dismiss', { duration: 5000 });
    })
  }

}
