import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from '../pages/about-us/about-us.component';
import { ContactUsComponent } from '../pages/contact-us/contact-us.component';
import { EventsComponent } from '../pages/events/events.component';
import { HomeComponent } from '../pages/home/home.component';
import { PrayerComponent } from '../pages/prayer/prayer.component';
import { PublicNoteComponent } from '../pages/public-note/public-note.component';
import { PublicSermonsComponent } from '../pages/public-sermons/public-sermons.component';
import { PublicComponent } from '../pages/public/public.component';
import { PubliccTestimoniesComponent } from '../pages/publicc-testimonies/publicc-testimonies.component';
import { SermonDetailsComponent } from '../pages/sermon-details/sermon-details.component';
import { SubmitTestimonyComponent } from '../pages/submit-testimony/submit-testimony.component';
import { TeamsComponent } from '../pages/teams/teams.component';

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [
      { 
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
      },
      {
        path: 'events',
        component: EventsComponent,
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
      },
      {
        path: 'team',
        component: TeamsComponent,
      },
      {
        path: 'prayer',
        component: PrayerComponent,
      },
      {
        path: 'sermons',
        component: PublicSermonsComponent,
      },
      {
        path: 'sermons/:id',
        component: SermonDetailsComponent,
      },
      {
        path: 'testimonies',
        component: PubliccTestimoniesComponent,
      },
      {
        path: 'submit-testimony',
        component: SubmitTestimonyComponent,
      },
      {
        path: 'note/:id',
        component: PublicNoteComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class PublicRoutingModule { }
