import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddSubmemberComponent } from '../attendance/pages/add-submember/add-submember.component';
import { CheckinComponent } from '../attendance/pages/checkin/checkin.component';
import { CurrentServiceComponent } from '../attendance/pages/current-service/current-service.component';
import { AttendanceLandingComponent } from '../attendance/pages/landing/landing.component';
import { RegisterMembersComponent } from '../attendance/pages/register-members/register-members.component';

const routes: Routes = [
  {
    path: 'event-checkin',
    component: AttendanceLandingComponent,
    children: [
        { 
          path: '',
          redirectTo: 'service',
          pathMatch: 'full',
        },
        {
          path: 'service',
          component: CurrentServiceComponent,
        },
        {
          path: 'checkin/:event-id',
          component: CheckinComponent,
        },
        {
          path: 'register-member',
          component: RegisterMembersComponent,
        },
        {
          path: 'add-submember/:mainMemberId',
          component: AddSubmemberComponent,
        },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AttendanceRoutingModule { }
